import React, { useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { NavLink } from "react-router-dom";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import Collapse from "@mui/material/Collapse";

import IconCategory from "@mui/icons-material/Category";
import IconDashboard from "@mui/icons-material/Dashboard";
import IconDepartments from "@mui/icons-material/AssignmentInd";
import IconBookings from "@mui/icons-material/Today";
import IconBusiness from "@mui/icons-material/Business";
import IconDevices from "@mui/icons-material/Devices";
import IconOrders from "@mui/icons-material/Assignment";
import IconTills from "@mui/icons-material/TouchApp";
import IconSettings from "@mui/icons-material/SettingsOutlined";
import IconSites from "@mui/icons-material/PinDrop";
import IconServers from "@mui/icons-material/SupervisedUserCircle";
import IconUsers from "@mui/icons-material/People";
import IconFood from "@mui/icons-material/Fastfood";
// Left to assist in migration to productTags
// import IconCategory from "@mui/icons-material/LocalOffer";
import IconPriceLevelSetups from "@mui/icons-material/PriceChange";
import IconProductOption from "@mui/icons-material/List";
import IconProductGroups from "@mui/icons-material/LocalOffer";
import IconSubGroups from "@mui/icons-material/SubdirectoryArrowRight";
import IconAllTags from "@mui/icons-material/Tag";
import IconGroupWork from "@mui/icons-material/GroupWork";
import IconUpdate from "@mui/icons-material/Update";
import IconHistory from "@mui/icons-material/History";
import IconMoneyOff from "@mui/icons-material/MoneyOff";
import IconPayment from "@mui/icons-material/Payment";
import IconPointOfSale from "@mui/icons-material/PointOfSale";
import IconShifts from "@mui/icons-material/Autorenew";
import IconTimeline from "@mui/icons-material/Timeline";
import IconTune from "@mui/icons-material/Tune";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import IconProductOptionGroup from "@mui/icons-material/Inventory";
import IconReceipt from "@mui/icons-material/Receipt";
import IconVoidRefundReasons from "@mui/icons-material/CreditCardOff";
import IconMyLocation from "@mui/icons-material/MyLocation";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ListAltIcon from "@mui/icons-material/ListAlt";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { toTestId } from "../../helpers";

export const drawerWidth = 260;

const SidebarLinksComponent = ({ appStore }) => {
  const dashboard = {
    href: "/app",
    icon: IconDashboard,
    title: "Dashboard",
  };
  const settings = {
    href: "/app/settings",
    icon: IconSettings,
    title: "Settings",
    visibleTo: ["SUPER"],
  };

  const items = [
    {
      href: "/app/auditLogs",
      icon: TroubleshootIcon,
      title: "Audit",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/bookings",
      icon: IconBookings,
      title: "Bookings",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/bookingtypes",
      icon: IconBookings,
      title: "Booking Types",
      visibleTo: ["SUPER"],
    },
    {
      icon: IconDevices,
      title: "Devices",
      visibleTo: ["SUPER"],
      children: [
        {
          href: "/app/tills",
          icon: IconTills,
          title: "Tills",
          visibleTo: ["SUPER"],
        },
      ],
    },
    {
      href: "/app/orders",
      icon: IconOrders,
      title: "Orders",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/sites",
      icon: IconSites,
      title: "Sites",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/price-level-setups",
      icon: IconPriceLevelSetups,
      title: "Price Level Setups",
      visibleTo: ["SUPER"],
    },
    {
      icon: IconFood,
      title: "Product Management",
      visibleTo: ["SUPER"],
      children: [
        {
          href: "/app/products",
          icon: LunchDiningIcon,
          title: "Individual",
          visibleTo: ["SUPER"],
        },
        {
          href: "/app/products/bulk",
          icon: MenuBookIcon,
          title: "Bulk",
          visibleTo: ["SUPER"],
        },
      ],
    },
    {
      href: "/app/promotions",
      icon: IconMoneyOff,
      title: "Promotions",
      visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
    },
    {
      icon: IconCategory,
      title: "Product Tags",
      visibleTo: ["SUPER"],
      children: [
        {
          href: "/app/product-tags",
          icon: IconAllTags,
          title: "All Tags",
          visibleTo: ["SUPER"],
        },
        {
          href: "/app/product-groups",
          icon: IconProductGroups,
          title: "Product Groups",
          visibleTo: ["SUPER"],
        },
        {
          href: "/app/sub-groups",
          icon: IconSubGroups,
          title: "Sub Groups",
          visibleTo: ["SUPER"],
        },
      ],
    },
    {
      icon: IconTune,
      title: "Product Options",
      visibleTo: ["SUPER"],
      children: [
        {
          href: "/app/product-options",
          icon: IconProductOption,
          title: "Options",
        },
        {
          href: "/app/product-option-groups",
          icon: IconProductOptionGroup,
          title: "Groups",
        },
      ],
    },
    {
      href: "/app/modifier-groups",
      icon: IconGroupWork,
      title: "Modifier Groups",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/users",
      icon: IconUsers,
      title: "Users",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/organisations/",
      icon: IconBusiness,
      title: "Organisations",
      visibleTo: ["SUPER"],
    },
    ...(appStore.settingsStore.showChangesets
      ? [
          {
            href: "/app/changesets",
            icon: IconUpdate,
            title: "Scheduled Changes",
            visibleTo: ["SUPER"],
          },
          {
            href: "/app/past-changesets",
            icon: IconHistory,
            title: "Change History",
            visibleTo: ["SUPER"],
          },
        ]
      : []),
    {
      icon: IconPayment,
      title: "Payment Types",
      visibleTo: ["SUPER"],
      children: [
        {
          href: "/app/payment-types",
          icon: IconTune,
          title: "Manage",
          visibleTo: ["SUPER"],
        },
        {
          href: "/app/pdq-payment-types",
          icon: IconPointOfSale,
          title: "PDQ Payment Types",
          visibleTo: ["SUPER"],
        },
      ],
    },
    {
      href: "/app/reports",
      icon: IconTimeline,
      title: "Reports",
      visibleTo: ["SUPER"],
    },
    {
      icon: IconServers,
      title: "Servers",
      visibleTo: ["SUPER"],
      children: [
        {
          href: "/app/departments",
          icon: IconDepartments,
          title: "Departments",
          visibleTo: ["SUPER"],
        },
        {
          href: "/app/server-setup",
          icon: ListAltIcon,
          title: "Server Setup",
          visibleTo: ["SUPER"],
        },
      ],
    },
    {
      href: "/app/shifts",
      icon: IconShifts,
      title: "Shifts",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/void-refund-reasons",
      icon: IconVoidRefundReasons,
      title: "Void & Refund Reasons",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/sales-areas",
      icon: IconMyLocation,
      title: "Sales Areas",
      visibleTo: ["SUPER"],
    },
    {
      href: "/app/print-texts",
      icon: IconReceipt,
      title: "Print Texts",
      visibleTo: ["SUPER"],
    },
  ];

  const [subMenusActive, setSubMenusActive] = useState({});

  const handleParentMenuClick = menu => {
    setSubMenusActive({
      ...subMenusActive,
      [menu]: !subMenusActive[menu],
    });
  };

  const handleMenuItemsRender = () => {
    let itemsToRender = items;

    if (appStore.session?.scopes) {
      itemsToRender = itemsToRender.filter(item =>
        item.visibleTo.find(
          permissionToFind => permissionToFind === appStore.session.scopes,
        ),
      );
    }

    itemsToRender = itemsToRender.sort(({ title: titleA }, { title: titleB }) =>
      titleA.localeCompare(titleB),
    );

    // Always put the dashboard first and settings last as we dont want them sorted
    return [dashboard, ...itemsToRender, settings];
  };

  return (
    <Slide direction="right" timeout={300} in>
      <List>
        {handleMenuItemsRender().map(
          ({ href, icon: Icon, title, children }, index) => {
            return children ? (
              <React.Fragment key={`parent-${title}`}>
                <ListItem button onClick={() => handleParentMenuClick(title)}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                  {subMenusActive[title] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {children.map(
                  ({ href: SubHref, icon: SubIcon, title: SubTitle }) => (
                    <Collapse
                      key={`lnk-${SubHref}`}
                      in={subMenusActive[title]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <NavLink
                        to={SubHref}
                        style={{ color: "black" }}
                        // without this /app will match every route
                        end={SubHref === "/app"}
                        data-testid={toTestId(`navlink-${SubTitle}`)}
                      >
                        {({ isActive }) => (
                          <ListItem button selected={isActive} sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <SubIcon />
                            </ListItemIcon>
                            <ListItemText primary={SubTitle} />
                          </ListItem>
                        )}
                      </NavLink>
                    </Collapse>
                  ),
                )}
                {!index && <Divider />}
              </React.Fragment>
            ) : (
              <NavLink
                to={href}
                key={`lnk-${href}`}
                style={{ color: "black" }}
                // without this /app will match every route
                end={href === "/app"}
                data-testid={toTestId(`navlink-${title}`)}
              >
                {({ isActive }) => (
                  <>
                    <ListItem button selected={isActive}>
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItem>
                    {!index && <Divider />}
                  </>
                )}
              </NavLink>
            );
          },
        )}
      </List>
    </Slide>
  );
};

SidebarLinksComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const SidebarLinks = inject("appStore")(observer(SidebarLinksComponent));
export default SidebarLinks;
