import React from "react";
import { PropTypes } from "prop-types";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const HlsIntegrationSettings = ({
  hlsSftpEndpoint,
  setHlsSftpEndpoint,
  hlsEndpointError,
  setHlsEndpointError,
}) => (
  <TableRow>
    <TableCell sx={{ minWidth: "200px" }}>
      <Typography>HLS Endpoint:</Typography>
    </TableCell>
    <TableCell>
      <Box>
        <TextField
          name="propertyManagementEndpoint"
          fullWidth
          value={hlsSftpEndpoint}
          onChange={event => {
            setHlsSftpEndpoint(event.target.value);
            setHlsEndpointError("");
          }}
          variant="standard"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            "data-testid": "hls-endpoint-input",
          }}
          error={!!hlsEndpointError}
          helperText={hlsEndpointError}
        />
      </Box>
    </TableCell>
  </TableRow>
);

HlsIntegrationSettings.propTypes = {
  hlsSftpEndpoint: PropTypes.string.isRequired,
  setHlsSftpEndpoint: PropTypes.func.isRequired,
  hlsEndpointError: PropTypes.string.isRequired,
  setHlsEndpointError: PropTypes.func.isRequired,
};

export default HlsIntegrationSettings;
