import React, { useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import HlsIntegrationSettings from "./Components/HlsIntegrationSettings";
import GuestlineIntegrationSettings from "./Components/GuestlineIntegrationSettings";

import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
} from "../../../../helpers/apollo/utils";
import PositiveAction from "../../../../components/Button/PositiveAction";

import { useSnackbar } from "notistack";

const availablePmsIntegrations = [
  { name: "None", value: "NULL" },
  { name: "HLS - SFTP", value: "HLS" },
  { name: "Guestline", value: "Guestline" },
];

const fqdnRegEx =
  "(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9].)+[a-zA-Z]{2,63}$)";

const PropertyManagementSettings = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [initialSelectedIntegration, setInitialSelectedIntegration] =
    useState("NULL");
  const [initialHlsSftpEndpoint, setInitialHlsSftpEndpoint] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState("NULL");
  const [initialGuestlineAnalysisCode, setInitialGuestlineAnalysisCode] =
    useState("");
  const [guestlineAnalysisCode, setGuestlineAnalysisCode] = useState("");
  const [hlsSftpEndpoint, setHlsSftpEndpoint] = useState("");
  const [hlsEndpointError, setHlsEndpointError] = useState("");

  useQuery(
    gql(
      GET_SETTINGS(
        "propertyManagementIntegration, hlsSftpEndpoint, guestlineTopLevelAnalysisCode",
      ),
    ),
    {
      onCompleted: ({ settings }) => {
        setInitialHlsSftpEndpoint(settings.hlsSftpEndpoint);
        setHlsSftpEndpoint(settings.hlsSftpEndpoint);
        setInitialSelectedIntegration(
          settings.propertyManagementIntegration || "NULL",
        );
        setSelectedIntegration(
          settings.propertyManagementIntegration || "NULL",
        );
        setInitialGuestlineAnalysisCode(settings.guestlineTopLevelAnalysisCode);
        setGuestlineAnalysisCode(settings.guestlineTopLevelAnalysisCode);
      },
      onError: e => {
        enqueueSnackbar(`Error fetching settings: ${e.message}`, {
          variant: "error",
          SnackbarProps: {
            "data-testid": "fetch-pms-settings-error-snackbar",
          },
        });
      },
    },
  );

  const [updateSettings] = useMutation(
    gql(
      UPDATE_SETTINGS(
        "propertyManagementIntegration, hlsSftpEndpoint, guestlineTopLevelAnalysisCode",
      ),
    ),
    {
      onCompleted: ({ updateSettings }) => {
        setInitialSelectedIntegration(
          updateSettings.propertyManagementIntegration,
        );
        setInitialHlsSftpEndpoint(updateSettings.hlsSftpEndpoint);
        setInitialGuestlineAnalysisCode(
          updateSettings.guestlineTopLevelAnalysisCode,
        );
        enqueueSnackbar("Settings saved", {
          variant: "success",
          SnackbarProps: {
            "data-testid": "update-pms-settings-success-snackbar",
          },
        });
      },
      onError: e => {
        enqueueSnackbar(`Error saving settings: ${e.message}`, {
          variant: "error",
          SnackbarProps: {
            "data-testid": "update-pms-settings-error-snackbar",
          },
        });
      },
    },
  );

  const handleSave = useCallback(() => {
    let input = {
      propertyManagementIntegration: selectedIntegration,
    };

    if (selectedIntegration === "HLS") {
      if (!hlsSftpEndpoint?.match(fqdnRegEx)) {
        setHlsEndpointError("Endpoint is not a valid URL");
        return;
      }
      input.hlsSftpEndpoint = hlsSftpEndpoint;
    } else if (selectedIntegration === "Guestline") {
      input.guestlineTopLevelAnalysisCode = guestlineAnalysisCode;
    }

    updateSettings({
      variables: {
        input,
      },
    });
  }, [selectedIntegration, hlsSftpEndpoint, guestlineAnalysisCode]);

  return (
    <>
      <TableRow>
        <TableCell sx={{ minWidth: "200px" }}>
          <Typography>Site Integration:</Typography>
        </TableCell>
        <TableCell>
          <Select
            sx={{ minWidth: "200px" }}
            value={selectedIntegration}
            onChange={({ target: { value } }) => setSelectedIntegration(value)}
            variant="standard"
            data-testid="site-integration-dropdown"
          >
            {availablePmsIntegrations.map(item => (
              <MenuItem
                key={item.name}
                value={item.value}
                selected={selectedIntegration === item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      </TableRow>
      {selectedIntegration === "HLS" ? (
        <HlsIntegrationSettings
          hlsSftpEndpoint={hlsSftpEndpoint || ""}
          setHlsSftpEndpoint={setHlsSftpEndpoint}
          hlsEndpointError={hlsEndpointError}
          setHlsEndpointError={setHlsEndpointError}
        />
      ) : selectedIntegration === "Guestline" ? (
        <GuestlineIntegrationSettings
          guestlineAnalysisCode={guestlineAnalysisCode || ""}
          setGuestlineAnalysisCode={setGuestlineAnalysisCode}
        />
      ) : null}
      <TableRow>
        <TableCell sx={{ borderBottom: "none", paddingTop: "50px" }}>
          <PositiveAction
            buttonText="Save"
            disabled={
              initialSelectedIntegration === selectedIntegration &&
              initialHlsSftpEndpoint === hlsSftpEndpoint &&
              initialGuestlineAnalysisCode === guestlineAnalysisCode
            }
            onClick={() => handleSave()}
            testId="save-button"
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default PropertyManagementSettings;
