import React from "react";
import { PropTypes } from "prop-types";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const GuestlineIntegrationSettings = ({
  guestlineAnalysisCode,
  setGuestlineAnalysisCode,
}) => (
  <TableRow>
    <TableCell sx={{ minWidth: "200px" }}>
      <Typography>Guestline Top Level Analysis Code:</Typography>
    </TableCell>
    <TableCell>
      <Box>
        <TextField
          name="guestlineAnalysisCode"
          fullWidth
          value={guestlineAnalysisCode}
          onChange={event => {
            setGuestlineAnalysisCode(event.target.value);
          }}
          variant="standard"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            "data-testid": "guestline-analysis-code-input",
          }}
        />
      </Box>
    </TableCell>
  </TableRow>
);

GuestlineIntegrationSettings.propTypes = {
  guestlineAnalysisCode: PropTypes.string.isRequired,
  setGuestlineAnalysisCode: PropTypes.func.isRequired,
};

export default GuestlineIntegrationSettings;
