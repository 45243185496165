import React from "react";
import { PropTypes } from "prop-types";

import PasswordInput from "../../../components/Form/PasswordInput";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const GuestlineSiteSettings = ({
  guestlineInterfaceId = "",
  guestlineSiteId = "",
  guestlineOperatorCode = "",
  guestlinePassword = "",
  handleGuestlineInputChange,
}) => {
  return (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          data-testid="guestline-interface-id"
          variant="standard"
          fullWidth
          id="guestlineInterfaceId"
          label="Guestline Interface ID"
          value={guestlineInterfaceId || ""}
          onChange={event =>
            handleGuestlineInputChange("guestlineInterfaceId")(
              event.target.value,
            )
          }
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          data-testid="guestline-site-id"
          variant="standard"
          fullWidth
          id="guestlineSiteId"
          label="Guestline Site ID"
          value={guestlineSiteId || ""}
          onChange={event =>
            handleGuestlineInputChange("guestlineSiteId")(event.target.value)
          }
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          data-testid="guestline-operator-code"
          variant="standard"
          fullWidth
          id="guestlineOperatorCode"
          label="Guestline Operator Code"
          value={guestlineOperatorCode || ""}
          onChange={event =>
            handleGuestlineInputChange("guestlineOperatorCode")(
              event.target.value,
            )
          }
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <PasswordInput
          data-testid="guestline-password"
          id="guestlinePassword"
          inputProps={{ "data-testid": "guestline-password" }}
          label="Guestline Password"
          value={guestlinePassword || ""}
          onChange={event =>
            handleGuestlineInputChange("guestlinePassword")(event.target.value)
          }
        />
      </Grid>
    </>
  );
};

GuestlineSiteSettings.propTypes = {
  guestlineInterfaceId: PropTypes.string,
  guestlineSiteId: PropTypes.string,
  guestlineOperatorCode: PropTypes.string,
  guestlinePassword: PropTypes.string,
  handleGuestlineInputChange: PropTypes.func.isRequired,
};

export default GuestlineSiteSettings;
