import React, { useState, useCallback } from "react";
import moment from "moment";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";
import { DataGrid } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";
import CustomTimePicker from "../../../components/Datagrid/CustomTimePicker";

import {
  ADD_SHIFT,
  GET_SHIFTS,
  UPDATE_SHIFT,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const isRequiredCell = params => {
  const {
    props,
    props: { value },
  } = params;

  return {
    ...props,
    error: !value,
  };
};

const columns = [
  {
    field: "code",
    headerName: "Code",
    type: "number",
    editable: true,
    minWidth: 150,
    align: "left",
    headerAlign: "left",
    preProcessEditCellProps: isRequiredCell,
  },
  {
    field: "name",
    headerName: "Name",
    type: "text",
    editable: true,
    minWidth: 150,
    flex: 1,
    preProcessEditCellProps: isRequiredCell,
  },
  {
    field: "startTime",
    headerName: "Start Time",
    type: "custom",
    editable: true,
    minWidth: 175,
    valueFormatter: value => {
      return value === null ? "" : moment.utc(value * 1000).format("hh:mm A");
    },
    renderEditCell: params => <CustomTimePicker params={params} />,
  },
  {
    field: "endTime",
    headerName: "End Time",
    type: "custom",
    editable: true,
    minWidth: 175,
    valueFormatter: value => {
      return value === null ? "" : moment.utc(value * 1000).format("hh:mm A");
    },
    renderEditCell: params => <CustomTimePicker params={params} />,
  },
];

export const generateNewRow = () => ({
  id: v4(),
  code: null,
  name: "",
  startTime: 0,
  endTime: 0,
  // We use this when updating a row to tell us this shift needs to be created
  createdRow: true,
});

const ShiftsBulkEditor = ({ appStore }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createdRow, setCreatedRow] = useState(false);
  const [tableData, setTableData] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-shifts-error-snackbar",
      },
    });
  };

  const { loading: loadingShifts } = useQuery(gql(GET_SHIFTS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const shifts = data?.shiftsWithCount?.shifts;

      if (shifts) {
        setTableData(shifts);
      }

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
  });

  const [addShift] = useMutation(gql(ADD_SHIFT()), {
    onCompleted: () => {
      enqueueSnackbar("Your new shift has been created", {
        SnackbarProps: {
          "data-testid": "bulk-shifts-added-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SHIFTS()), "Shifts"],
  });

  const [updateShift] = useMutation(gql(UPDATE_SHIFT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "bulk-shifts-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SHIFTS()), "Shifts"],
  });

  const addRow = () => {
    const newRow = generateNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const handleSubmitRow = useCallback(
    async (newRow, oldRow) => {
      const rowUpdates = Object.fromEntries(
        Object.entries(newRow).filter(
          ([key, val]) => key in oldRow && oldRow[key] !== val,
        ),
      );

      if (Object.keys(rowUpdates).length) {
        const submitData = {
          variables: {
            input: rowUpdates,
          },
        };

        if (newRow.createdRow !== undefined) {
          submitData.variables.input.startTime = newRow.startTime;
          submitData.variables.input.endTime = newRow.endTime;
          return addShift(submitData).then(() => {
            setCreatedRow(false);
            return newRow;
          });
        } else {
          submitData.variables.input.id = newRow.id;
          return updateShift(submitData).then(() => {
            return newRow;
          });
        }
      } else {
        return newRow;
      }
    },
    [addShift, updateShift],
  );

  return (
    <StyledBox>
      <DataGrid
        columns={columns}
        editMode="row"
        loading={loadingShifts}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={handleSubmitRow}
        onProcessRowUpdateError={error => errorSnackbar(error.message)}
      />
    </StyledBox>
  );
};

ShiftsBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(ShiftsBulkEditor));
