import React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import ShiftsBulkEditor from "./Components/ShiftsBulkEditor";

const TillsList = () => {
  const title = "Shifts";

  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2" data-testid="main-header">
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "0px" }}>
              <ShiftsBulkEditor />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default TillsList;
